import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter, Inject,
    Input,
    OnChanges,
    OnInit,
    Output, PLATFORM_ID,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {ActionButtonConfig} from '../../models/action-button-config';
import {FormControlConfig} from '../../models/form-control-config';
import {ActionButtonComponent} from '../action-button/action-button.component';
import {TextInputComponent} from '../text-input/text-input.component';
import {NgFor, NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.css'],
    standalone: true,
    host: {ngSkipHydration: 'true'},
    imports: [FormsModule, ReactiveFormsModule, NgFor, TextInputComponent, NgTemplateOutlet, ActionButtonComponent]
})
export class DynamicFormComponent implements OnInit, OnChanges {

    @Input() outputValidationState: boolean = false;
    /**
     * The definition of the form controls
     */
    @Input() controlsConfig: FormControlConfig[];

    /**
     * an array of action button configurations
     */
    @Input() submitActionButtons: ActionButtonConfig[];

    /**
     * Disable the submit button when the form is invalid
     * @default true
     */
    @Input() disableInvalid: boolean = true;

    /**
     * Add validators on the form level
     */
    @Input() formGroupValidators: ValidatorFn[];

    /**
     * Whether the form submit buttons should be disabled
     */
    @Input() disabled: boolean;

    /**
     * Whether to submit the form following an "ENTER" key press
     * @default true
     * Note: The mandatory condition for this input to be considered is that
     * only a single submit button is passed to the form (i.e: if there is a submit button and also
     * a cancellation button, the ENTER press will not submit the form)
     */
    @Input() submitOnEnterKey: boolean = true;

    /**
     * An event emitter to be fired on form submit
     */
    @Output() onSubmit: EventEmitter<any>;

    /**
     * An event emitter to be fired on form change validation
     */
    @Output() onFormChangeValidation: EventEmitter<boolean>;


    @Output() onFormChangeOutput: EventEmitter<any>;

    /**
     * Optional template reference for extra elements to inject inside the form
     */
    @ContentChild('extraContent') extraContentRef?: TemplateRef<any> = null;


    get formControls() {
        return this.dynamicForm.controls;
    }

    dynamicForm: FormGroup;

    constructor(private formBuilder: FormBuilder) {
        this.onSubmit = new EventEmitter<any>();
        this.onFormChangeValidation = new EventEmitter<boolean>();
        this.onFormChangeOutput = new EventEmitter<any>();
    }

    ngOnInit() {
        if (this.outputValidationState) {
            this.dynamicForm.valueChanges.subscribe(() => {
                this.onFormChangeValidation.emit(this.dynamicForm.invalid)
                this.onFormChangeOutput.emit(this.dynamicForm.controls)
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const {controlsConfig} = changes;
        if (controlsConfig && controlsConfig.previousValue !== controlsConfig.currentValue) {
            this.setFormGroup();
        }
    }


    setFormGroup = () => {
        this.dynamicForm = this.formBuilder.group({});
        this.controlsConfig.forEach(control => {
            this.dynamicForm.addControl(
                control.name,
                new FormControl(
                    {
                        value: control.value || "",
                        disabled: control.disabled
                    },
                    control.validators
                )
            );
        });

        if (this.formGroupValidators) {
            this.dynamicForm.setValidators(this.formGroupValidators);
        }

    }

    onEnterKeyPress = (event: KeyboardEvent) => {
        event.stopPropagation();
        if (this.shouldSubmit()) {
            this.submit(this.submitActionButtons[0]);
        }
    }

    shouldSubmit = (): boolean => {
        return (
            !this.disabled &&
            this.dynamicForm.valid &&
            this.submitActionButtons &&
            this.submitActionButtons.length === 1
        );
    }

    submit = (button: ActionButtonConfig) => {
        const value = this.dynamicForm.getRawValue();

        if (button.onClick) {
            button.onClick(value)
        } else {
            this.onSubmit.emit(value);
        }
    }

}
